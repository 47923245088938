export const informationLink = "https://www.smartiscity.gr/dimos-n-skoufa/";
export const informationLinkEN =
  "https://www.smartiscity.gr/en/dimos-n-skoufa/";

export const diavLink = "https://diavouleuseis.nskoufas.gr";

export const applicantsCitizen = [
  {
    name: "Σύνδεση",
    url: "https://skoufa.cityzenapp.gr/cityzen/dimosskoufa/login",
  },
  {
    name: "Εγγραφή",
    url: "https://skoufa.cityzenapp.gr/cityzen/dimosskoufa/register",
  },
  {
    name: "Πληροφορίες",
    url: "https://skoufa.cityzenapp.gr/cityzen/dimosskoufa/",
  },
];

export const androidAppLink =
  "https://play.google.com/store/apps/details?id=gr.dotsoft.cityzenapp.skoufa";
export const iosAppLink =
  "https://apps.apple.com/tt/app/δημότης-σκουφά/id1610133667";

export const welcomeMessageContent = [
  "Ο Δήμος Ν. Σκουφά σας καλωσορίζει στην Πλατφόρμα Εξυπνης Πόλης.",
  "Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.",
];

export const baseUrl = "https://skoufas.smartiscity.gr";

export const allTypesUrl = baseUrl + "/api/api.php?func=sensortypes";
export const allSensorsUrl = baseUrl + "/api/api.php?func=allsensors";

export const UvUrl = baseUrl + "/api/api.php?func=indexes";
export const enviURL = baseUrl + "/api/api.php?func=envi";
export const meshURL = baseUrl + "/api/api.php?func=mesh";
export const meteoURL = baseUrl + "/api/api.php?func=meteo";
export const poiURL = baseUrl + "/api/api.php?func=pois";
export const fleetoUrl = baseUrl + "/api/api.php?func=fleeto";
export const binsUrl = baseUrl + "/api/api.php?func=bins";
export const hydroUrl = baseUrl + "/api/api.php?func=hydro";
export const weatherUrl = baseUrl + "/api/api.php?func=forecast";
export const wifiUrl = baseUrl + "/api/api.php?func=hotspot";
export const hamURL = baseUrl + "/api/api.php?func=ham";

export const allBinsCharts = baseUrl + "/api/api.php?func=allbinstats";
export const specificBinChart = baseUrl + "/api/api.php?func=binstats";

export const chartsUrls = baseUrl + "/api/api.php?func=stats";

export const gallerySmall = baseUrl + "/gallery/vars/Small/";
export const galleryBig = baseUrl + "/gallery/vars/Big/";

export const cityCenter = {
  lat: 39.1410388,
  lng: 20.9739672,
};

// settings
export const showEnvironmentAddress = true;
